@tailwind base;
@tailwind components;

#heading {
  line-height: normal
}

#description {
  line-height: 2rem;
}

.btn {
  display: none;
}

#cardTemplate:hover .btn {
  display: block;
  transition: all;
  transition-duration: 500ms;
}

#bg {
  background-image: url("./img/Footer.png");
  background-size: cover;
  z-index: -1;
}

/* // Start Scroll Templates */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
/* // End Scroll Templates */

/* // Delte Button start  */
#editbtn:hover .delete{
  position: absolute;
  right: 12;
  background-color: pink;
}
/* // Delte Button End  */

/* Compoenent 4 Start  */
.hrTag{
  border-top: 1px solid black;
}
/* Compoenent 4 End  */

/* Component7 List Disc[Bullet Colored] Start */
.ListColorComp7 li::before {
  content: "\2022";
  color: #009c6877;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
/* Component7 List Disc[Bullet Colored] Start */

.logo{
  height: 100px;
  margin-top: -30px;
  margin-bottom: 30px;
}
.logo1{
  height: 100px;
  margin-bottom: 30px;
}

@tailwind utilities;